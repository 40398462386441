import 'ol/ol.css';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import KML from 'ol/format/KML.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector.js';
import Feature from 'ol/Feature.js';
import {defaults as defaultControls} from 'ol/control.js';
import {Icon, Style} from 'ol/style.js';



var zoom = 9;
var center = [2194555.132,6932017.441];

var styleFunction = function(feature) {
        var styleName = feature.get('styleUrl');
        var style = new Style({
            image: new Icon({
              src: styleName + '.png',
			  scale: 0.14,
			  opacity: 0.8
            })
          });
		return style;
        };	

var pamiec_IIws = new VectorLayer({
	source: new VectorSource({
	  url: 'data/pamiec_IIws.kml',
      format: new KML({
            extractStyles: false,
			showPointNames: false
          })
        }),
		style: styleFunction
  });
  
var obozy = new VectorLayer({
source: new VectorSource({
  url: 'data/obozy.kml',
  format: new KML({
		extractStyles: false,
		showPointNames: false
	  })
	}),
	style: styleFunction
});
	  
	  
var map = new Map({
  controls: defaultControls({
		attributionOptions: {
			collapsible: true
		}
    }),
  target: 'map',
  layers: [
    new TileLayer({
      source: new OSM()
    }),pamiec_IIws,obozy
  ],
  view: new View({
    center: [2194555.132,6932017.441],
      projection: 'EPSG:3857',
	  center: center,
      zoom: zoom
  })
});